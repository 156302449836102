<template>
  <div class="list-page">
    <div class="img-wrap">
      <img src="@/assets/img/logo_M.png" />
    </div>
    <div class="list">
      <router-link class="link" :to="{ name: '每週檢察項目表單' }">
        <img src="~@/assets/img/1.png" />
        <div>
          <span>每週一出車前檢查</span>
          <div class="name">每週檢查項目表單</div>

          <div class="note">(若有異常及維修情況，可於當週日前修改資料)</div>
        </div>
      </router-link>

      <router-link class="link" :to="{ name: '每日檢察項目表單' }">
        <img src="~@/assets/img/2.png" />
        <div>
          <div class="name">每日檢查項目表單</div>
          <div class="note">(低溫品裝載前，請務必確認溫度有達預打標準)</div>
        </div>
      </router-link>
      <router-link class="link" :to="{ name: '每日里程維護' }" v-if="!IsOuter">
        <img src="~@/assets/img/3.png" />
        <div>
          <div class="name">每日里程紀錄維護</div>
        </div>
      </router-link>
    </div>

    <!-- <button
      type="button"
      class="btn btn-block btn-danger btn-save"
      @click.prevent="next"
    >
      下一步
    </button> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      type: "",
      IsOuter:false,
    };
  },
  methods: {
    next() {
      if (this.type === "everyWeek") {
        this.$router.push("weekly_inspection");
      } else if (this.type === "everyDay") {
        this.$router.push("daily_inspection");
      } else if (this.type === "everyDayMileage") {
        this.$router.push("daily_maintenance");
      }
    },


    // userLogin() {
    //   const vm = this;
    //   const api = `${process.env.VUE_APP_API}/Common/Auth/UOFRedirectLogIn`;
      
    //   const data = { 
    //     UofAccount:"FyUhi1HUs6IDFGNEsNBXAA%3D%3D",
    //     LogInTime:"goOFibjCO%2FQopSADB8A0sQ%3D%3D",
    //   };

    //   vm.$http
    //     .post(api, data)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         console.log("response.data",response.data);
    //         localStorage.setItem("token", response.data.Data.Token);
    //         localStorage.setItem("user", JSON.stringify(response.data.Data));
    //       }
    //     })
    //     .catch((error) => {
    //       Notification({
    //         title: "錯誤",
    //         message: error.response.data,
    //         type: "error",
    //         duration: 2500,
    //       });
    //     });
    // },
  },
  created() {
    

    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("this.user",this.user);
    this.IsOuter = this.user.IsOutsideMember; //是否為外部
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .list-page {
    background-color: #e50013;
    height: 100vh;
    .img-wrap{
      text-align: center;
    }
    
    .list {
      max-width: 480px; 
      margin: 0 auto; 
      .link {
        background-color: #fff;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        border-radius: 35px;
        padding: 5px 5px 5px 15px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
        min-height: 65px;
        text-decoration: none;

        img {
          width: 45px;
          margin-right: 10px;
        }
        & > div {
          color: #000;
          span {
            display: block;
            font-weight: normal;
            font-size: 0.7em;
            opacity: 0.6;
          }
          .name {
            font-size: 1.1em;
            font-weight: bold;
          }
          .note {
            font-size: 0.75em;
            width: 100%;
            color: #d53f4c;

            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .list-page {
    background-color: #f1f1f1;
    height: 100vh;

    .img-wrap {
      padding: 40px;
      background-color: #e50013;
      img {
        width: 100%;
      }
    }
  }
  .list {
    padding: 0 10px;
    margin-top: -20px;
    position: relative;
    z-index: 10;

    .link {
        text-decoration: none;
      background-color: #fff;
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      border-radius: 35px;
      padding: 5px 5px 5px 15px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
      min-height: 65px;

      img {
        width: 45px;
        margin-right: 10px;
      }
      & > div {
        color: #000;
        span {
          display: block;
          font-weight: normal;
          font-size: 0.7em;
          opacity: 0.6;
        }
        .name {
          font-size: 1.1em;
          font-weight: bold;
        }
        .note {
          font-size: 0.75em;
          width: 100%;
          color: #d53f4c;

          text-align: center;
        }
      }
    }
  }
}
</style>